<template>
  <div>
    <v-container>

      <!-- back to CRM -->
      <router-link :to="{ name: 'CRM' }">Zurück zum CRM</router-link>
      <v-row>
        <!-- Serch button -->
        <v-col cols="11" xl="4">
          <v-text-field
            v-model="searchStr"
            outlined
            label="Nach E-Mail-Adresse suchen"
            append-icon="mdi-magnify"
            @keydown.enter="search"
            @click:append="search"
          ></v-text-field>
        </v-col>
        <v-col cols="1"></v-col>

        <!-- Stat Buttons -->
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">
                {{ stats.totalPageCount }}
              </div>
              <div class="caption">Abgeschlossen</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">
                {{ stats.freePageCount }}
              </div>
              <div class="caption">Testmonat</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">
                {{ stats.inactivePageCount }}
              </div>
              <div class="caption">Inaktiv</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">
                {{ stats.payingUsers }}
              </div>
              <div class="caption">Bezahlt</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">
                {{ stats.canceledPageCount }}
              </div>
              <div class="caption">Gekündigt</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">{{ stats.monthlyIncome.toFixed(2) }}€</div>
              <div class="caption">p. M.</div>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col cols="2" xl="1">
          <v-card class="unvergessen-shadow card">
            <v-responsive :aspect-ratio="1 / 1">
              <div class="title">{{ stats.totalIncome.toFixed(2) }}€</div>
              <div class="caption">insg.</div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>

      <v-alert type="error" v-model="pageNotFound">
        Nutzer konnte nicht gefunden werden
      </v-alert>
      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="pages"
        class="unvergessen-shadow"
        :items-per-page="-1"
      >
        <!-- created time -->
        <template v-slot:item.ct="{ item }">
          <div
            style="
              width: 100%;
              height: 100%;
              cursor: pointer;
              padding-top: 15px;
            "
            @click="handleClick(item)"
          >
            <span>{{ new Date(item.ct).toLocaleDateString() }}</span>
          </div>
        </template>
        <!-- Status with translations -->
        <template v-slot:item.status="{ item }">
          <span>{{ item.status === 'paid' ? 'Bezahlt'
            : item.status === 'testMonth' ? 'Testmonat'
            : item.status === 'inactive' ? 'Inaktiv'
            : item.status === 'pending' ? 'Nicht abgeschlossen'
            : item.status === 'canceled' ? 'Gekündigt'
            : 'Gelöscht' }}</span>
        </template>
        <!-- e3Date -->
        <template v-slot:item.e3Date="{ item }">
          <div v-if="item.e3Date != null">
            <span>{{ new Date(item.e3Date).toLocaleDateString() }}</span>
          </div>
        </template>
        <!-- comments view -->
        <template v-slot:item.notes="{ item }">
          <v-row>
            <v-col cols="9">
              <span>
                {{ item.notes }}
              </span>
            </v-col>
            <v-col cols="3">
              <v-icon @click.stop="handleNotes(item)"> mdi-pencil </v-icon>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <div v-if="index > 0 && !hideInfinite" v-intersect="loadMore"></div>
      <div v-if="indexSerch > 0 && !hideInfinite" v-intersect="search"></div>

      <!-- Dialogs -->
      <v-dialog max-width="600px" v-model="noteDialog">
        <v-card style="padding: 20px">
          <v-textarea outlined v-model="currentNote"></v-textarea>
          <div style="padding: 20px; text-align: center">
            <v-btn class="success" @click="editNote"> Speichern </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      index: 0,
      indexSerch: 0,
      hideInfinite: false,
      noteDialog: false,
      currentNote: '',
      currentItem: {},
      currentIndex: -1,
      pageNotFound: false,
      searchStr: '',
      stats: {
        freePageCount: 0,
        monthlyIncome: 0,
        payingUsers: 0,
        totalIncome: 0,
        totalPageCount: 0,
        inactivePageCount: 0,
        canceledPageCount: 0
      },
      pages: [],
      headers: [
        {
          text: 'Erstellt',
          align: 'start',
          value: 'ct'
        },
        { text: 'E-Mail-Adressen', value: 'mail' },
        { text: 'ID', value: '_id' },
        { text: 'Status', value: 'status' },
        { text: 'E3-Date', value: 'e3Date' },
        { text: 'Modell', value: 'paymentModel' },
        { text: 'Kommentar', value: 'notes' }
      ]
    }
  },
  methods: {
    handleClick (item) {
      this.$router
        .push({
          name: 'CRMPageSingleUser',
          params: { uid: item._id }
        })
        .catch(() => {})
    },
    handleNotes (item) {
      this.currentNote = item.notes
      this.currentItem = item
      this.currentIndex = this.pages.indexOf(item)
      this.noteDialog = true
    },
    editNote () {
      this.currentItem.notes = this.currentNote
      if (this.currentIndex > -1) {
        axios
          .patch('/crm/page/page/notes', {
            page_id: this.currentItem._id,
            notes: this.currentNote
          })
          .then(() => {
            Object.assign(this.pages[this.currentIndex], this.currentItem)
            this.currentIndex = -1
            this.currentItem = {}
            this.currentNote = ''
            this.noteDialog = false
          })
          .catch()
      }
    },
    search () {
      axios
        .post('/crm/page/page/search', { key: this.searchStr, set: 0 })
        .then((res) => {
          this.pageNotFound = false
          this.hideInfinite = true
          this.pages = []
          this.stats.freePageCount = res.data.freePageCount
          this.stats.monthlyIncome = res.data.monthlyIncome
          this.stats.payingUsers = res.data.payingUsers
          this.stats.totalIncome = res.data.totalIncome
          this.stats.totalPageCount = res.data.totalPageCount
          this.stats.inactivePageCount = res.data.inactivePageCount
          this.stats.canceledPageCount = res.data.canceledPageCount
          this.pages = res.data.pages
          if (this.indexSerch === 0) {
            this.indexSerch = 1
          } else {
            this.indexSerch++
          }
        })
        .catch(() => {
          this.pages = []
          this.pageNotFound = true
        })
    },
    loadMore () {
      axios.get('/crm/page', { params: { set: this.index } })
        .then(res => {
          if (res.data.pages.length === 0) {
            this.hideInfinite = true
          } else {
            this.pages = this.pages.concat(res.data.pages)
            this.index++
          }
        })
    }
  },
  created () {
    axios.get('/crm/page', { params: { set: 0 } }).then((res) => {
      // Fill stats
      this.stats.freePageCount = res.data.freePageCount
      this.stats.monthlyIncome = res.data.monthlyIncome
      this.stats.payingUsers = res.data.payingUsers
      this.stats.totalIncome = res.data.totalIncome
      this.stats.totalPageCount = res.data.totalPageCount
      this.stats.inactivePageCount = res.data.inactivePageCount
      this.stats.canceledPageCount = res.data.canceledPageCount
      this.pages = res.data.pages
      this.index = 1
    })
  }
}
</script>

<style scoped>
.card {
  text-align: center;
  border-radius: 10px !important;
  margin-right: 30px;
}

* >>> .v-responsive__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

* >>> td {
  font-size: 12px !important;
}
</style>
